body {
  margin: 0;
  font-family: "arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-1oqqzyl-MuiContainer-root {
  max-width: 94% !important;
}

button.MuiButtonBase-root.MuiButton-root:hover {
  background-color: #530068;
  color: white;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeLarge.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
  color: black !important;
}
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 18px !important;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}