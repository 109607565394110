.app {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* Stack elements vertically */
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.services-hero {
  width: 100%;
  height: 250px;
  /* Adjust the height as needed */
  background-image: url('../assets/services-hero.jpg');
  /* Update with the correct path to your background image */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  /* Center the content vertically */
  justify-content: center;
  /* Center the content horizontally */
}

.services-hero h2 {
  color: #ffffff;
  /* Adjust text color as needed */
  font-size: 2.5rem;
  /* Adjust font size as needed */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  /* Optional: Adds a shadow for better readability */
}

.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* This will handle the first row */
  align-content: flex-start;
  /* Aligns wrapped lines at the start of the container */
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  gap: 20px;
  /* This sets a gap between the cards */
}

.service-container::after {
  content: '';
  width: calc(33.333% - 40px);
  /* This should match the width of your service cards */
}

.service-card {
  box-sizing: border-box;
  width: calc(33.333% - 40px);
  margin: 10px;
  text-align: center;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Ensure smooth transitions for transform and shadow */
}

.service-card:hover {
  transform: scale(1.05); /* Scales the card up slightly */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  /* transform: rotateY(10deg); */
}

.service-card img {
  width: 40px;
  /* Adjust width as necessary */
  height: 40px;
  /* Adjust height to match width to maintain aspect ratio */
  margin: 0 auto;
  display: block;
  background-color: transparent;
  /* Default background color */
  padding: 15px;
  /* Ample padding to display the full background */
  border-radius: 30%;
  /* Full circle by default */
  transition: background-color 0.3s, border-radius 0.3s;
}

.hover-color {
  background-color: transparent;
  /* Reset background color */
}

.service-card:hover .hover-color {
  background-color: var(--hover-color);
  /* Apply hover color */
  border-radius: 20%;
  /* Desired border-radius on hover */
  transform: scale(1.1);
  /* Slightly scale up the image on hover */
}

.service-link {
  text-decoration: none;
  /* Remove underline */
  color: inherit;
  /* Inherit color from parent */
}

.service-title {
  margin-bottom: 0;
  /* Remove bottom margin to maintain spacing */
}

@media (min-width: 769px) {
  .service-container::after {
    content: '';
    flex-grow: 1;
  }
}

/* This ensures 2 cards per row on medium screens and the last card aligns left with the cards above */
@media (max-width: 1200px) {
  .service-container::after {
    content: none;
    /* Remove the pseudo-element so it doesn't affect layout */
  }

  .service-card {
    width: calc(50% - 40px);
    /* 2 cards per row */
  }
}

/* This ensures 1 card per row on small screens */
@media (max-width: 768px) {
  .service-card {
    width: calc(100% - 40px);
    /* 1 card per row */
  }
}