.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  /* Removed padding to match the image */
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  /* Use the font that best matches your design */
  color: #333;
  /* Text color for the whole container */
  margin-bottom: 30px;
}

.about-hero {
  width: 100%;
  height: 250px;
  /* Increased height to match the image */
  background-image: url('../assets/about-us-hero.jpg');
  /* Ensure you have the correct path */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-hero h2 {
  color: #ffffff;
  font-size: 2.5rem;
  /* Increased font size to match the image */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
  /* Remove margin for better control */
}

.services-main-content {
  display: flex;
  flex-direction: column;
  /* Change to column layout on smaller screens */
  margin: 20px auto;
  /* Adjust as needed */
  padding: 0 20px;
  /* Add horizontal padding */
  max-width: 1200px;
  /* Adjust as needed */
}

.left-content,
.right-content {
  flex: 1;
  /* Adjust as needed */
  text-align: center;
  /* Center align content on smaller screens */
  margin-bottom: 20px;
  /* Add space between left and right content */
}

@media (max-width: 767px) {
  .about-hero h2 {
    font-size: 2rem;
  }

  .services-header h1 {
    font-size: 1.5rem;
  }
}

/* Media Query for smaller screens */
@media (min-width: 768px) {
  .services-main-content {
    flex-direction: row;
    /* Change back to row layout on larger screens */
    align-items: flex-start;
    /* Align content to the top on larger screens */
  }

  .left-content,
  .right-content {
    text-align: left;
    /* Align text to the left on larger screens */
    margin-bottom: 0;
    /* Remove margin between left and right content on larger screens */
  }

  .left-content {
    padding-right: 20px;
    /* Ensure there is some space between the columns */
  }

  .right-content {
    padding-left: 20px;
    /* Ensure there is some space between the columns */
  }
}

/* Adjust your h1 styles if necessary */
.services-header h1 {
  font-size: 2.5rem;
  color: #000;
  margin: 0 0 20px 0;
  /* Adjust as needed */
}

.services-list {
  list-style: none;
  padding: 0;
  text-align: left;
  /* Align text to the left */
}

.services-list li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

.services-list li::before {
  content: '✔';
  color: #4caf50;
  /* Changed to a Material Design green for checkmark */
  margin-right: 5px;
  font-weight: bold;
  font-size: 1.2rem;
  /* Slightly larger checkmark */
}
